.content_main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content_container {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: white;
  padding: 5px 15px;
  color: rgba(57, 126, 230);
}

/* .content_container button {
    height: 100%;
} */

.content_container h1 {
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.08rem;
}

.content_body_container {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  padding: 0px 15px;
  position: relative;
}

.more_menu {
  height: 100%;
  aspect-ratio: 1/1;
  background: var(--blue-light);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  position: relative;
  cursor: pointer;
}

.more_list {
  position: absolute;
  min-width: 180px;
  max-height: 300px;

  background-color: white;
  z-index: 1000000000;
  right: 0;
  top: 110%;
  border-radius: 10px;
  overflow-y: auto;
}

.more_cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
}

.more_list_item {
  padding: 7px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgba(57, 126, 230, 0.1);
  color: black;
}

.more_list_item svg {
  height: 25px;
  width: 25px;
  padding: 5px;
  border-radius: 5px;
}

.more_list_item svg path {
  fill: var(--brand-color);
}

.more_list_item:hover {
  background-color: rgba(57, 126, 230, 0.2);
}

.search_section {
  display: flex;
  margin-left: auto;
  height: 37px;
  width: 300px;
  border-radius: 10px;
  background: white;
  /* overflow: hidden; */
  align-items: center;
  padding-left: 10px;
}

.search_section svg {
  height: 17px;
  color: lightgrey;
}

.search_section input {
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9rem;
  padding: 10px;
  flex: 1;
}

@media only screen and (max-width: 700px) {
  .content_container {
    display: flex;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    height: fit-content;
    gap: 20px;
    overflow-x: auto;
    height: 60px;
  }
}

@media only screen and (max-width: 430px) {
  .content_container {
    display: flex;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    height: fit-content;
    gap: 2px;
    overflow-x: auto;
    height: 60px;
  }
}
