.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.field_container {
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  position: relative;
  background: white;
  border: 1px solid rgb(235, 235, 235);
  display: flex;
  flex-direction: column;
  gap:15px;
}

.field_container .toggle {
  position: absolute;
  top: 20px;
  right: 20px;
}
.text_editor_container {
  margin-top: 20px;
}

.text_editor_container p {
  font-size: 16px;
  font-weight: 500;
  transform: translateX(5px);
  margin-bottom: 5px;
}

.custom_title_container {
  position: relative;
}

.custom_title_section {
  position: absolute;
  bottom: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  right: 5px;
  gap: 10px;
  border: 1px dashed lightgrey;
  border-radius: 10px;
  padding-inline: 10px;
  font-size: 0.9rem;
}

.media_container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  overflow: hidden;
}

.field_container h3 {
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}

.field_container p {
  font-size: 0.9rem;
}

.variant_with_img_and_name {
  display: flex;
  gap: 20px;
  border: 0.5px solid #cecece;
  padding: 15px 10px;
  border-radius: 10px;
  position: relative;
  align-items: flex-end;
}

.select_media {
  border: 2px dashed lightgrey;
  border-radius: 10px;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.selected_media {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.media_image {
  width: 200px;
  aspect-ratio: 1/1;
  border: 1px solid black;
  border-radius: 10px;
  /* overflow: hidden; */
  /* overflow: hidden; */
  position: relative;
  display: flex;
  align-items: center;
}

.media_image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
  border-radius: 10px;
}

.media_image svg {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
  background: #c1ddfd;
  height: 20px;
  width: 20px;
  padding: 5px;
  border-radius: 100%;
}

.select_media input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
}

.select_media p:first-child {
  background: #eff5fc;
  color: #36619b;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
}

.select_media p:last-child {
  opacity: 0.5;
  margin-top: 10px;
}

.in_single_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.variant_heading {
  display: flex;
  align-items: center;
}

.variant_heading button {
  background: #eff5fc;
  border: none;
  padding: 5px 10px;
  color: #36619b;
  font-weight: 600;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.5s;
}

.variant_heading button:hover {
  background: #e5f1ff;
}

.variant_heading div:last-child {
  background: #eff5fc;
  border: none;
  padding: 5px 10px;
  color: #36619b;
  font-weight: 600;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.5s;
}

.variants_container {
  /* border: 2px solid red; */
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 10px;
  gap: 10px;
}

.variant_container {
  border: 1px solid rgba(128, 128, 128, 0.324);
  padding: 20px 10px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  border-radius: 10px;
  position: relative;
}

.remove_variant {
  position: absolute;
  height: 30px;
  width: 30px;
  background: rgba(0, 0, 0, 0.406);
  border-radius: 100%;
  right: 0;
  top: 0;
  transform: translateX(40%) translateY(-40%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}

.remove_variant:hover {
  background: rgba(0, 0, 0, 0.569);
}

.variant_option_values {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.color_option_values {
  padding: 5px 10px;
  background: #c1ddfd;
  border-radius: 5px;
  color: #36619b;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
}

.color_option_values svg {
  cursor: pointer;
}

.text_area {
  margin-top: 20px;
}

.text_area p {
  font-size: 0.9rem;
  font-weight: 500;
  transform: translateX(5px);
  color: #464646;
}

.text_area textarea {
  margin-top: 5px;
  border-radius: 10px;
  min-height: 100px;
  max-width: 100%;
  min-width: 100%;
  border: 1px solid rgb(222, 222, 222) !important;
  background: transparent !important;
  padding: 10px;
  font-size: 0.9rem;
  font-family: inherit;
}

.variant_option_value {
  padding: 5px 10px;
  background: #c1ddfd;
  border-radius: 5px;
  color: #36619b;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
}

.variant_option_value svg {
  cursor: pointer;
}

.variant_option_value svg:hover {
  color: black;
}

/* modal start */
.modal_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_container {
  height: 80vh;
  width: 80vw;
  background: white;
  border-radius: 10px;
  position: relative;
  display: grid;

  grid-template-columns: 1fr 300px;
}

.modal_container svg {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  background: #c1ddfd;
  height: 25px;
  width: 25px;
  padding: 5px;
  border-radius: 100%;
}

.image_preview {
  height: 100%;
  width: 100%;
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.image {
  height: 80%;
  width: fit-content;
}

.image_preview img:first-child {
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 10;
}

/* .image_preview img:last-child{
    position: absolute;
    height: 80%;
    width: 50%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    object-fit: cover;
    
} */

.image_list {
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px 0;
}

.image_list ul {
  width: 100%;
  height: 100%;
  list-style: none;
  border-left: 0.5px solid black;
}

.image_list ul li {
  height: 50px;
  padding-inline: 10px;
  border-bottom: 0.3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.li_active {
  background: #e2eefd;
}

.hidden_input_arrows input::-webkit-outer-spin-button,
.hidden_input_arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Color Picker Section */
.color_picker_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.color_and_add_btn {
  padding-top: 29px;
  display: flex;
}

.responsive_grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-top: 25px;
}
@media (min-width: 600px) {
  .responsive_grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .responsive_grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
  
  .field_container {
    padding: 20px;
  }
}

.color_part {
  height: 60px;
  border: 1px solid rgba(222, 222, 222);
  width: auto;
  aspect-ratio: 1/1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}

.color_part svg {
  height: 30px;
}

.palette_part {
  position: absolute;
  height: fit-content;
  width: fit-content;
  bottom: 90%;
  left: 90%;
  transform-origin: left bottom;
  z-index: 10000;
  border-radius: 10px;
  background: white;
}

.palette_btns {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.palette_btns button {
  flex-grow: 1;
  height: 30px;
  border: none;
  color: white;
  border-radius: 5px;
}

.palette_btns button:first-child {
  background: red;
}

.palette_btns button:last-child {
  background: green;
}

.add_part {
  height: 60px;
  border: 1px solid rgba(222, 222, 222);
  width: auto;
  aspect-ratio: 2/1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
  background: #eff5fc;
  color: #36619b;
  transition: 0.5s;
}

.add_part:hover {
  background: #e2eefd;
}

/* End */

.document_uploaded_field {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  gap: 10px;
  position: relative;
  overflow: hidden;
}

.document_remove_btn {
  position: absolute;
  height: 85%;
  aspect-ratio: 1/1;
  background-color: white;
  right: -100px;
  border-radius: 10px;
  transition: 0.5s;
  padding: 10px;
  cursor: pointer;
}

.document_remove_btn svg {
  height: 100%;
  width: 100%;
}

.document_uploaded_field:hover .document_remove_btn {
  right: 5px;
}


@media screen and (max-width: 468px) {


  .field_container{
    width:100%;
  }


  
}