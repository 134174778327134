.invoice {
  width: 220mm; /* A4 width */
  height: 297mm; /* A4 height */
  padding: 15mm;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;

}
.inner_container{
border: 1px solid #ddd;
height: 100%;
/* padding: 10mm; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 3mm;
  padding-bottom: 5mm;
  /* margin-bottom: 10mm; */
}

.logoContainer {
  width: 150px;
  height: 70px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #ddd; /* Light border to see the container */
}

.logoImage {
  max-width: 100%;
  max-height: 100%;
  height: 70px; /* Fixed height */
  width: auto; /* Width will adjust to maintain aspect ratio */
  object-fit: contain;
  display: block !important;
  visibility: visible !important;
}

/* Add print-specific styles for images */
@media print {
  .logoContainer {
    display: block !important;
    visibility: visible !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    height: 70px !important; /* Fixed height for print */
  }
  
  .logoContainer svg {
    display: block !important;
    visibility: visible !important;
    border: 1px solid red !important;
  }
  
  .logoImage {
    display: block !important;
    visibility: visible !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    max-width: 100%;
    height: 70px !important; /* Fixed height for print */
    width: auto !important; /* Width will adjust to maintain aspect ratio */
    object-fit: contain !important;
  }
  
  /* Force all images to be visible in print */
  img {
    display: block !important;
    visibility: visible !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  
  /* Force all SVGs to be visible in print */
  svg {
    display: block !important;
    visibility: visible !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

.companyDetails {
  width: 50%;
}

.invoiceTitle {
  text-align: right;
  align-self: end;
  font-size: 0.9rem;
  color:  grey;
}

.invoiceDetails {
  padding: 3mm;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DDD;
}

.detailsColumn {
  flex: 1;
  padding: 0 2mm;
  display: flex;
  align-items: center;
}

.detailsColumn:not(:last-child) {
  border-right: 1px solid #DDD;
}

.detailsColumn p {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1mm;
}

.detailsColumn strong {
  color: #666;
  font-size: 0.9em;
}

.detailsColumn span {
  font-weight: 500;
}

.customerDetails {
  text-align: left;
  text-transform: capitalize;
  min-height: 30mm;
  display: flex;
  flex-direction: column;
}

.customerDetails h5 {
  border-bottom: 1px solid #DDD;
  padding: 1mm 2mm;
  margin: 0;
}

.addressContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
}

.addressColumn {
  flex: 1;
  padding: 1mm 0mm;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.addressDivider {
  width: 1px;
  background-color: #DDD;
  margin: 0;
}

.addressColumn h5 {
  margin-bottom: 1mm;
  padding-bottom: 3mm;
}

.addressColumn div {
  padding-inline: 2mm;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.addressColumn p {
  margin: 0.5mm 0;
  line-height: 1.4;
}

.addressColumn p:first-child {
  font-weight: 500;
}

/* .table {
  margin-bottom: 20mm;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  border: 1px solid #ddd;
  padding: 5mm;
  text-align: left;
} */

.table {
  width: 100%;
  margin: 0 auto;
}

.table table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-transform: capitalize;
  word-break: keep-all;
}

.table th {
  background-color: #f2f2f2;
  text-align: right;
  font-size: 12px;
  /* font-weight: 600; */
}

.table th[colspan=2] {
  text-align: center;
}


.table td {
  vertical-align: top;
  font-size: 12px;
   text-align: right;
}

.table tr td:first-child{
  text-align: left;
}

.table td:nth-child(2) {
  width: 40%;
}

.summary {
  padding: 3mm;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.amountInWords {
  width: 60%;
}

.totalAmount {
  width: 30%;
  /* border: 1px solid red; */
}
.totalAmount p{
  display: flex;
  padding-block: 1px;
  justify-content: space-between;
}

.footer {
  margin-top: 3mm;
  font-size: 14px;
  padding: 3mm;
}

.signature {
  text-align: right;
  margin-top: 10mm;
}

.signature img {
  width: 60mm;
}
