.responsive-grid {
  display: grid;
  gap: 20px;
}

.responsive-card {
  display: grid;
  gap: 20px;
}

.responsive-grid_2 {
  display: grid;
  gap: 20px;
}

.responsive-input-group {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.responsive-input-wrapper {
  height: 52px;
  width: 100%;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 10px;
  display: flex;
  gap: 5px;
}

.responsive-select {
  border: none;
  outline: none;
  border-radius: 10px;
  height: 100%;
  width: fit-content;
  flex-shrink: 0;
}

.responsive-input {
  border: none;
  outline: none;
  border-radius: 0 10px 10px 0;
  flex: 1;
}

@media (max-width: 768px) {
  .responsive-grid_2 {
    grid-template-columns: 1fr;
  }
}