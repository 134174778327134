/* Base styles */
.table_container {
    overflow-x: auto;
    margin: 0 auto;
    padding: 0px;
    flex: 1;
    width: 100%;
    z-index: 1; 
}

.search_section {
    display: flex;
    margin-left: auto;
    height: 37px;
    width: 300px;
    border-radius: 10px;
    background: white;
    overflow: hidden;
    align-items: center;
    padding-left: 10px;
    border: 1px solid #ddd;
    margin-right: 10px;
}

.search_section svg {
    height: 17px;
    color: lightgrey;
}

.search_section input {
    height: 100%;
    border: none;
    outline: none;
    font-size: 0.9rem;
    padding: 10px;
    flex: 1;
}

.responsive_table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
    color: #212529;
    background-color: transparent;
    display: table;
    position: relative;
}

.responsive_table tr:hover {
    background-color: var(--blue-light);
    cursor: pointer;
}

.responsive_table th,
.responsive_table td {
    padding: 12px;
    text-align: left;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-right: none;
    border-left: none;
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.responsive_table td {
    font-size: 0.9rem;
}

.responsive_table th {
    background-color: rgb(217, 229, 251);
    color: #495057;
    font-weight: 500;
    font-size: 0.9rem;
}

.responsive_table thead {
    position: sticky;
    top: 0;
    min-width: 100%;
    z-index: 100;
}

/* .filter_container {
  display: flex;
  gap: 20px;
  font-size: 0.9rem;
  background: var(--blue-light);
  padding: 7px 20px;
  align-items: center;
} */

.filter_container {
    display: flex;
    gap: 20px;
    font-size: 0.9rem;
    background: var(--blue-light);
    padding: 7px 20px;
    align-items: center;
    position: sticky;
    top: 0px;
    z-index: 9999;
    height: 51;
 
}

.drop_down_container {
    position: relative;
}

.drop_down_content svg {
    height: 15px;
    width: 15px;
}

.drop_down_content {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.contact_owner_search {
    border: 1px solid lightgrey;
    height: 40px;
    width: 250px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.contact_owner_search svg {
    flex-shrink: 0;
    padding: 10px;
}

.contact_owner_search input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    border-radius: 5px;
}

.contact_owner_single_list {
    display: flex;
    gap: 10px;
    padding: 10px 20px;
}

.contact_owner_single_list:hover {
    background: var(--blue-light);
}

.drop_down_section {
    position: absolute;
    top: 100%;
    height: fit-content;
    min-width: 250px;
    background: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 0px;
    z-index: 1000; 
}

.search_section {
    display: flex;
    margin-left: auto;
    height: 37px;
    width: 300px;
    border-radius: 10px;
    background: white;
    overflow: hidden;
    align-items: center;
    padding-left: 10px;
}

.search_section svg {
    height: 17px;
    color: lightgrey;
}

.search_section input {
    height: 100%;
    border: none;
    outline: none;
    font-size: 0.9rem;
    padding: 10px;
    flex: 1;
}

.editable_cell:hover {
    outline-width: 1px;
    outline-color: var(--blue-dark);
    outline-style: solid;
}

.on_click_input_field,
.on_click_select {
    padding: 12px;
    height: 100%;
    width: fit-content;
    min-width: 100%;
    border: none;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 0px;
}

.on_click_input_field {
    box-shadow: none;
    font-size: 0.9rem;
    width: fit-content;
}

.on_click_select {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.on_click_select svg {
    height: 13px;
    width: 13px;
}

.fixed_list {
    position: absolute;
    top: 100%;
    height: fit-content;
    width: 300px;
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    padding-top: 10px;
    z-index: 100000000000000;
    overflow: hidden;
}

.fixed_list ul {
    list-style: none;
    margin-top: 10px;
    max-height: 250px;
    overflow: auto;
}

.fixed_list ul li {
    padding: 10px;
}

.fixed_list ul li:hover {
    background: var(--blue-light);
    cursor: pointer;
}

.fixed_search_section {
    display: flex;
    margin-left: auto;
    height: 37px;
    width: 95%;
    margin-inline: auto;
    border-radius: 10px;
    background: white;
    overflow: hidden;
    align-items: center;
    padding-left: 10px;
    border: 1px solid lightgrey;
}

.fixed_search_section svg {
    height: 17px;
    color: lightgrey;
}

.fixed_search_section input {
    height: 100%;
    border: none;
    outline: none;
    font-size: 0.9rem;
    padding: 10px;
    flex: 1;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100000;
}

/* Responsive styles */
@media (max-width: 768px) {

    .responsive_table,
    .responsive_table thead,
    .responsive_table tbody,
    .responsive_table th,
    .responsive_table td,
    .responsive_table tr {
        display: block;
    }

    .responsive_table thead tr {
        /* position: absolute; */
        top: -9999px;
        left: -9999px;
    }

    .responsive_table tr {
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

    .responsive_table td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        text-align: right;
    }

    .responsive_table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
    }

    .responsive_table td input[type="checkbox"] {
        margin-left: 0;
        /* Ensure checkbox is aligned properly */
    }
}

/* Ensure table cells expand and contract based on content */
.responsive_table th,
.responsive_table td {
    text-align: left;
    padding: 8px;
    white-space: nowrap;
}

.responsive_table {
    table-layout: auto;
    /* Let the table layout be determined by the content */
}

.secondary_view {
    display: flex;
    width: 100%;
    flex: 1;
    height: 100%;
    overflow: hidden;
}

.secondary_list_view {
    width: 250px;
    height: 100%;
    border-right: 1px solid #d2d2d2;
    flex-shrink: 0;
    overflow-y: auto;
}

.secondary_short_item {
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: start;
    border-bottom: 0.5px solid #d2d2d2;
}

.secondary_short_item:hover {
    background: var(--blue-light);
    cursor: pointer;
}

.secondary_short_item div {
    font-size: 0.85rem;
}

.secondary_short_item input[type="checkbox"] {
    margin-top: 5px;
}

.secondary_short_item div p {
    text-transform: capitalize;
}

.secondary_detail_view {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 100px;
}

.secondary_container {
    padding: 20px;
}

.sdv_head {
    height: 50px;
    display: flex;
    align-items: center;
    padding-inline: 15px;
    justify-content: space-between;
}

.sdv_head>p {
    font-size: 1.1rem;
}

.sdv_buttons {
    display: flex;
    gap: 15px;
    align-items: center;
}

.sdv_buttons button {
    height: 30px;
 
  
    padding-inline: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    cursor: pointer;
    /* border: 1px solid black; */
    background: transparent;
}

.sdv_buttons svg {
    height: 15px;
    width: 15px;
}

/* .sdv_buttons button:nth-child(1) {
    color: black;
} */

.sdv_buttons button:nth-child(1) svg path {
    stroke: var(--blue-dark);
}

.sdv_buttons button:nth-child(2) svg path {
    stroke: red;
}

/* .sdv_buttons button:nth-child(2) {
    color: black;
} */

.sdv_close_btn {
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;
}

.secondary_content {
    padding: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
}

.secondary_content_2 {
    padding: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
}

.secondary_content span,
.secondary_content_2 span {
    font-size: 0.8rem;
    color: grey;
}

.secondary_content p,
.secondary_content_2 p {
    font-size: 0.9rem;
}

.tabs_section {
    padding-inline: 20px;
    border-bottom: 1px solid grey;
    display: flex;
}

.tabs_section div {
    padding-inline: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.9rem;
    cursor: pointer;
}

.tab_selected {
    font-weight: 600;
    border-bottom: 2px solid var(--brand-color);
}

.remarks {
    padding: 20px;
}

.remark_input {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.remark_input label {
    font-size: 0.8rem;
    color: grey;
}

.remark_input textarea {
    height: 100px;
    border-radius: 10px;
    padding: 10px;
    outline: none;
}

.remark_btn {
    width: 100%;
}

.remark_btn button {
    float: right;
    padding: 10px 20px;
    background: var(--brand-color);
    color: white;
    border-radius: 5px;
    outline: none;
    border: none;
}

.old_remarks {
    padding-top: 15px;
}

.old_remarks h3 {
    font-size: 1.1rem;
    font-weight: 500;
}

.old_remarks_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 20px;
    padding-left: 10px;
}

.old_remarks_sub_container {
    width: 100%;
    height: fit-content;
    border-left: 1px solid lightgrey;
    position: relative;
    display: flex;
}

.old_remarks_dot {
    /* position: absolute; */
    flex-shrink: 0;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: var(--brand-color);
    /* left: 0;
  top: 0; */
    transform: translateX(-50%) translateY(90%);
    box-shadow:
        0 0 10px rgba(0, 150, 255, 0.8),
        /* Stronger main glow */
        0 0 20px rgba(0, 150, 255, 0.6),
        /* Additional spread */
        0 0 30px rgba(0, 150, 255, 0.4);
    /* Further spread */
    animation: glow 1.5s infinite alternate;
}

@keyframes glow {
    0% {
        box-shadow:
            0 0 10px rgba(0, 150, 255, 0.6),
            0 0 20px rgba(0, 150, 255, 0.4),
            0 0 30px rgba(0, 150, 255, 0.2);
    }

    100% {
        box-shadow:
            0 0 20px rgba(0, 150, 255, 0.8),
            0 0 30px rgba(0, 150, 255, 0.6),
            0 0 40px rgba(0, 150, 255, 0.4);
    }
}

.old_remarks_heading {
    padding-top: 5px;
    padding-left: 5px;
}

.old_remarks_heading p {
    font-size: 0.8rem;
    color: grey;
}

.old_remarks_heading p:nth-child(2) {
    font-size: 0.9rem;
    color: black;
}

.history {
    padding-inline: 20px;
}

.history_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 20px;
    padding-left: 10px;
}

.history_sub_container {
    width: 100%;
    height: fit-content;
    border-left: 1px solid lightgrey;
    position: relative;
    display: flex;
    padding: 20px 0;
}

.history_dot {
    /* position: absolute; */
    flex-shrink: 0;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: var(--brand-color);
    /* left: 0;
  top: 0; */
    transform: translateX(-50%) translateY(90%);
    box-shadow:
        0 0 10px rgba(0, 150, 255, 0.8),
        /* Stronger main glow */
        0 0 20px rgba(0, 150, 255, 0.6),
        /* Additional spread */
        0 0 30px rgba(0, 150, 255, 0.4);
    /* Further spread */
    animation: history-glow 1.5s infinite alternate;
}

@keyframes history-glow {
    0% {
        box-shadow:
            0 0 10px rgba(0, 150, 255, 0.6),
            0 0 20px rgba(0, 150, 255, 0.4),
            0 0 30px rgba(0, 150, 255, 0.2);
    }

    100% {
        box-shadow:
            0 0 20px rgba(0, 150, 255, 0.8),
            0 0 30px rgba(0, 150, 255, 0.6),
            0 0 40px rgba(0, 150, 255, 0.4);
    }
}

.history_heading {
    width: 100%;
    padding-top: 2px;
    padding-left: 5px;
}

.history_heading h4 {
    font-weight: 500;
}

.history_time {
    font-size: 0.8rem;
    color: grey;
}

.history_heading button {
    outline: none;
    border: none;
    background-color: transparent;
    color: var(--brand-color);
    text-decoration: underline;
    text-transform: capitalize;
}

.history_table_container {
    width: 100%;
    margin-top: 7px;
}

.history_table_container table,
th,
td {
    border: 0.1px solid #d2d2d2;
}

.history_table_container thead tr th {
    font-size: 0.8rem;
    font-weight: 500;
    background-color: var(--blue-light);
    opacity: 1;
    z-index: 100;
}

.history_table_container tbody tr td {
    font-size: 0.8rem;
}

.history_table_container table {
    width: 100%;
    border-collapse: collapse;
    display: table;
}

.history_table_container th,
td {
    padding: 7px 10px;
    text-align: center;
}


.mobile_view {
    flex-direction: column;
  }
  
  .hidden {
    display: none !important;
  }
  
  .mobile_toggle {
    display: none;
    background: #f5f5f5;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .mobile_toggle_btn {
    padding: 8px 16px;
    margin: 0 5px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .mobile_toggle_btn.active {
    background: var(--brand-color);
    color: white;
    border-color: var(--brand-color);
  }
  
 
  .tab_content_container {
    overflow-y: auto;
    max-height: calc(100vh - 160px);
  }
  

  .responsive_table {
    width: 100%;
    border-collapse: collapse;
  }
  
 
  @media screen and (max-width: 468px) {
    .secondary_view {
      flex-direction: column;
      height: auto;
      overflow: hidden;
    }
  
    .mobile_toggle {
      display: flex;
      justify-content: center;
    }
    .filter_container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 10px;
        font-size: 0.9rem;
        background: var(--blue-light);
        padding: 0;
        align-items: center;
        position: sticky;
        top: 0px;
     
        height: fit-content;
        z-index: 9999;
    
    }
}