.button_container {
  height: 40px;
  width: fit-content;
  padding: 0 10px;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 5px;
  background: transparent;
  font-size: 1rem;
}

.filled {
  background: black;
  color: white;
}

.outlined {
  border: 1px solid black;
  color: black;
}

.input_filed {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  font-size: 1rem;
}

.table_container {
  height: 100%;
  /* width: calc(100vw - 110px); */
  width: 100%;
  /* border-radius: 10px; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: auto;
}

.table {
  width: 100%;
  border-radius: 10px;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;

}

.table th {
  text-align: center;
  position: sticky;
  top: 0;

  font-weight: 500;
  white-space: nowrap;

}

.table th,
.table td {
  padding: 0.7rem;
  border-right: none;
  border-left: none;
  text-align: center;


}

.table th {
  font-size: 0.8rem;
  text-align: center;

}

.table td {
  font-weight: 300;
  font-size: 0.8rem;
}

.table thead {
  height: fit-content;
  background: rgba(57, 126, 230, 0.2);
}

.table tr:hover td {
  background: rgba(99, 99, 99, 0.1);
  cursor: pointer;
}

.table tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-transform: capitalize;
}

.remarks {
  width: fit-content;
  position: relative;
  cursor: pointer;
}

.remarks::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: black;
  bottom: 0;
  left: 0;
  transition: 0.5s;
}

.remarks:hover::after {
  background: rgba(0, 0, 0, 0.2);
  height: 50%;
}

.dropdown:last-child {
  right: 10px;
  left: unset;
}

.dropdown {
  position: absolute;
  top: 2.6rem;
  left: 0px;
  border: 0.5px solid rgb(178, 177, 177);
  background-color: rgb(164, 190, 230);
  padding: 0rem 0rem;
  min-width: 15vw;
  max-width: 30vw;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown p {
  font-size: 0.7rem !important;
  padding-block: 0.5rem;

  cursor: pointer;

  /* border-bottom: 1px solid black; */
}

.dropdown_btn {
  margin-block: 1.5vh;
  display: flex;
  justify-content: space-around;
}

.dropdown_btn button {
  padding: 3px 8px;
  background-color: transparent;
  border: 1px solid black;
  cursor: pointer;
}

.drop_val {
  display: flex;
  padding-left: 1.5rem;
  gap: 1rem;
  border-bottom: 0.3px solid lightgray;
}