.cardStyle {
  position: relative;
  border-radius: 16px;
  padding: 24px;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
  transform: none;
  min-height: 500px;
}

.cardStyle.popular {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.cardStyle.hover {
  transform: translateY(-8px);
  border: 2px solid #2563eb;
  background-color: #f0f7ff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.titleStyle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
  color: #1f2937;
}

.titleStyle.popular {
  color: var(--brand-color);
}

.priceContainerStyle {
  text-align: center;
  margin-bottom: 24px;
}

.priceStyle {
  font-size: 2.25rem;
  font-weight: bold;
  display: inline-block;
}

.periodStyle {
  color: #6b7280;
  margin-left: 4px;
}

.gstStyle {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 4px;
  display: block;
}

.featureListStyle {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.featureItemStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  color: #4b5563;
}

.buttonStyle {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  background-color: #f3f4f6;
  color: #4b5563;
  transition: background-color 0.2s ease;
  margin-top: auto;
}

.buttonStyle.popular {
  background-color: var(--brand-color);
  color: #ffffff;
}

.popularBadgeStyle {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--brand-color);
  color: white;
  padding: 4px 16px;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}

.seeMoreContainer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.seeMoreButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background-color: transparent;
  color: var(--brand-color);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.seeMoreButton:hover {
  opacity: 0.8;
}

.confirmationModelStyle {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationCardStyle {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  width: 600px;
}

.confirmationCardHeadStyle {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.confirmationCardHeadStyle p {
  font-weight: 500;
}

.confirmationCardHeadStyle svg {
  height: 1.4rem;
  width: 1.4rem;
  cursor: pointer;
  color: gray;
}

.confirmationCardBodyStyle {
  padding: 10px 10px 20px 10px;
  border-bottom: 1px solid lightgray;
}

.confirmationCardBodyStyle span {
  font-weight: 500;
  color: var(--brand-color);
}

.confirmationCardFooterStyle {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.confirmationCardFooterStyle button {
  border: none;
  height: 2rem;
  padding-inline: 10px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.proceedConfirmationButton {
  background-color: var(--brand-color);
  color: white;
}
