.applayout_container {
  height: calc(100vh - 50px);
  width: 100vw;
  display: flex;
  overflow: hidden;
  padding: 5px;
  gap: 10px;
}

/* **********NAV BAR START********** */
.nav_container {
  height: 50px;
  font-size: 1.6rem;
  font-weight: 600;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-right: 3vw;
  border-radius: 10px;
}

.nav_container span {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  letter-spacing: 2px;
}

.nav_container button {
  border: none;
  background: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
/* **********NAV BAR END********** */

.sidebar_logo {
  width: 100%;
  margin-top: 5px;
  display: flex;
  /* justify-content: center; */
}

.sidebar_logo img {
  width: 70%;

  /* padding-right: 25px; */
}

.sidebar_and_content_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.profile_menu {
  position: absolute;
  height: fit-content;
  width: 150px;
  background: white;
  right: 0;
  top: 110%;
  z-index: 100000000;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 5px;
  list-style: none;
  font-size: 0.9rem;
}

.profile_menu li {
  padding: 7px 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 7px;
  color: gray;
}

.profile_menu li:hover {
  background: var(--blue-light);
  cursor: pointer;
}

.profile_menu li svg {
  height: 16px;
  width: 16px;
}

.profile_menu li svg {
  stroke: lightgrey;
}

.sidebar_container {
  width: 14vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  flex-shrink: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sidebar_text {
  width: 0;
  overflow: hidden;
  padding-left: 0px;
  white-space: nowrap;
  color: grey;
}

.sidebar_text_show {
  padding-left: 7px;
  width: fit-content;
}

.sidebar_main {
  position: relative;
  width: 100%;
  height: 100%;
  /* background: rgb(236, 236, 236); */
  background: #fefefa;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  z-index: 100;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 5px;
}

.expand_icon {
  position: absolute;
  height: 15px !important;
  width: 15px !important;
  right: 10px;
  top: 35%;
  transform-origin: center;
  transform: rotate(0);
}

.content_container {
  width: 100%;
  height: 100%;
  /* padding: 0 20px 20px 20px; */
  overflow: hidden;
  padding: 0px;
  border-radius: 10px;
  margin-top: 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #fefefa;
}

/* **********MENU ICON START********** */

.menu_items_main {
  background: rgba(57, 126, 230, 0.1);
  border-radius: 10px;
}

.menu_icon_container {
  width: 55px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  cursor: pointer;
  overflow: hidden;
}

.menu_line_one,
.menu_line_two,
.menu_line_three {
  height: 2px;
  width: 27px;
  background: rgb(74, 74, 74);
  border-radius: 50px;
  transition: 0.5s;
  transform-origin: center left;
}

.open_menu_icon .menu_line_one {
  transform: rotateZ(45deg);
}

.open_menu_icon .menu_line_two {
  transform: rotateY(-90deg);
}

.open_menu_icon .menu_line_three {
  transform: rotateZ(-45deg);
}
/* **********MENU ICON END********** */

.bottom_items_container {
  list-style: none;
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: auto;
}

.menu_items_container {
  list-style: none;
  width: 100%;
  /* height: 100%; */
  position: relative;
}

.menu_items_container_list_item,
.bottom_item {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 10px;
  margin-bottom: 0px;
  position: relative;
  padding-left: 7px;
  position: relative;
}

.bottom_item svg {
  height: 24px;
  width: 20px;
}

.bottom_item svg path {
  fill: gray;
}

.bottom_item:hover {
  background: rgba(57, 126, 230, 0.2);
}

.bottom_item_active {
  background: rgb(57, 126, 230);
}

.bottom_item_active span {
  color: white;
}

.bottom_item_active svg path {
  fill: white;
}

.bottom_item_active:hover {
  background: rgb(57, 126, 230);
}

.over_content {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  right: 10px;
  top: 17.5px;
  transform: translateY(-50%);
  cursor: pointer;
}

.menu_sub_items {
  padding-top: 5px;
  padding-inline: 5px;
  padding-bottom: 0px;
  max-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.menu_sub_items_open {
  max-height: 1000px;
  padding-bottom: 5px;
}

.menu_sub_items_single {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  color: grey;
}

.menu_sub_single_selected {
  background: rgb(57, 126, 270);
  color: white;
}

.menu_sub_single_selected:hover {
  background: rgb(57, 126, 270) !important;
}
.menu_sub_single_selected svg path {
  fill: white !important;
}
.over_sub_content {
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
}

.sub_menu_head_open {
  background: rgba(57, 127, 270, 0.3) !important;
}

.sub_menu_head_open span {
  color: rgb(57, 127, 270);
}

.sub_menu_head_open svg path {
  fill: rgb(57, 127, 270) !important;
}

.menu_sub_items_single:hover .over_sub_content {
  display: block;
}

.menu_sub_items_single:hover {
  background: rgba(57, 126, 230, 0.2);
}

.menu_sub_items_single svg {
  height: 18px !important;
  width: 18px !important;
}

.menu_sub_items_single p {
}

.menu_items_container li:hover .tool_tip {
  display: block;
}

.tool_tip {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.24);
  font-size: 0.8rem;
  width: fit-content;
  white-space: nowrap;
  left: 110%;
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
}

.menu_items_container_list_item:hover {
  background: rgba(57, 126, 230, 0.1);
  border-radius: 10px;
}

.menu_items_container_list_item.selected:hover {
  background: rgba(57, 126, 230);
}
.menu_items_container li:hover.selected {
  background: rgb(57, 126, 230);
}

.menu_items_container li:last-child {
  position: absolute;
  bottom: 1px;
  width: calc(100% - 6px);
}

.menu_items_container li svg {
  height: 24px;
  width: 20px;
  padding: 0 !important;
  margin: 0 !important;
  flex-shrink: 0;
}

.menu_items_container li svg path {
  fill: grey;
}

.selected {
  background: rgb(57, 126, 230);
  border-radius: 10px;
}

.selected span {
  color: white !important;
}

.selected svg path {
  fill: white !important;
}

.sidebar_main_mob {
  display: none;
}
.sidebar_mob {
  display: none;
}
.user_and_menu {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  .user_and_menu {
    width: 20vw;
  }

  .sidebar_mob {
    display: block;
  }

  .sidebar_desktop {
    display: none;
  }
  .applayout_container {
    flex-direction: column;
  }
  .menu_items_container {
    display: none;
  }
  .sidebar_container {
    height: fit-content;
    width: fit-content;
    overflow: visible;
    padding: 0;
    position: absolute;
    z-index: 99999999 !important;
    top: 5px;
    right: 10px;
    box-shadow: none;
  }

  .sidebar_main {
    display: none;
  }

  .sidebar_main_mob {
    display: block;
    height: 40px;
    width: fit-content;
    /* border: 1px solid red; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px;
    /* position: relative; */
    background-color: transparent;
    z-index: 999999;
  }

  .mob_logo {
    z-index: 100;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .menu_mob_btn {
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
  }

  .open_menu_icon .menu_line_one {
    transform: rotateZ(45deg);
  }

  .open_menu_icon .menu_line_two {
    transform: rotateY(-90deg);
  }

  .open_menu_icon .menu_line_three {
    transform: rotateZ(-45deg);
  }

  .mub_overlay {
    /* position: absolute; */
    height: 10px;
    width: 100%;
    /* background: white; */
    /* right: 0;
    top: 0; */
    border-radius: 10px;
    height: 100%;
    z-index: 10;
    transition: 0.3s;
    overflow: hidden;
    /* border: 2px solid red; */
  }

  .open_menu_mob {
    height: calc(100vh - 20px);
    width: 100vw;
    position: absolute;
    right: 0px;
    top: 0px;
    transition: 0.3s;
    background-color: white;
  }

  .menu_items_container_mob {
    padding-top: 100px;
    padding-inline: 20px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .menu_items_container_mob li {
    border-radius: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .sidebar_and_content_container {
    height: 100%;
    padding: 0;
  }
}
