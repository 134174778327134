@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --blue-dark: #36619b;
  --blue-light: #eff5fc;
  --brand-color: rgba(57, 126, 230);
  --tertiary-color: #868686;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select,
select option {
  text-transform: capitalize !important;
}

.ql-toolbar.ql-snow {
  border: none;
  /* border-bottom: 1px solid grey; */
  border-radius: 15px 15px 0 0;
  background: #b9b9b9;
  border-width: 1px !important;
  border-color: transparent !important;
}

.ql-container.ql-snow {
  border-radius: 0 0 15px 15px;
  min-height: 300px;
  border-width: 1px !important;
  border-color: transparent !important;
  /* background-color: rgb(211, 211, 211); */
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
}

.optimizing {
  animation: slide 2s ease-out infinite;
}

@keyframes slide {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translate(150%);
  }
  100% {
    transform: translate(-50%);
  }
}

.loader {
  width: 40px;
  height: 40px;
  border: 3.5px solid #1e1e1e;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn_loader_white {
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.btn_loader_black {
  width: 15px;
  height: 15px;
  border: 2px solid #1e1e1e;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

textarea {
  background-color: white !important;
  border: 1px solid lightgrey !important;
  /* stop expanding in horizontally */
  resize: vertical;
}

textarea:focus {
  outline: 1px solid black !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

.card {
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background: white;
  /* box-shadow: rgba(209, 209, 209, 0.16) 0px 1px 10px; */
  border: 1px solid rgb(235, 235, 235);
  align-items: start;
  font-size: 0.9rem !important;

}

.card_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card h3 {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
}

.card > h3 > button {
  background: var(--blue-light);
  color: var(--blue-dark);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.h-fit {
  height: fit-content;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.grid_2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.grid_3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.grid_4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_style {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.input_style label {
  transform: translateX(5px);
}

.input_style div {
  height: 50px;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 10px;
  font-size: 18px;
  position: relative;
}

.input_style div svg {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.input_style div select,
.input_style div input {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  padding: 10px;
  border: none;
}

.input_style div select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}

.input_style div select::-ms-expand {
  display: none;
}

.input_style div select:focus,
.input_style div input:focus {
  outline: none;
}

.media_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-light);
  color: var(--blue-dark);
  height: 100px;
  border-radius: 20px;
  border: 1px dashed var(--blue-dark);
  position: relative;
}

.media_container input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.media_content_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.media {
  height: 100px;
  width: 100px;
  border: 1px dashed black;
  border-radius: 20px;
  display: flex;
  position: relative;
}

.media img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 20px;
}

.media svg {
  position: absolute;
  background: var(--blue-light);
  color: var(--blue-dark);
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 100%;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.toggle_input_container {
  width: 100%;
  border: 1px dashed grey;
  height: 50px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  margin-top: 29px;
  cursor: pointer;
}

.selected_media_container {
  display: flex;
  flex-wrap: wrap;
}

.media_content {
  height: 150px;
  width: 150px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  position: relative;
}

.media_content img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.media_content svg {
  position: absolute;
  background: var(--blue-light);
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 100%;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.sub_container {
  width: 100%;
  height: fit;
  border: 1px dashed var(--brand-color);
  padding: 15px;
}
